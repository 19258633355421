import classNames from 'classnames';
import React, { FC } from 'react';

import edgeIcon from '../../../../../public/icons/edge.svg';
import { formatNumToCurrency } from '../../../../utils/formatter.utils';

import styles from './HomeownerOffers.module.scss';

interface Offer {
  tier: string;
  // eslint-disable-next-line camelcase
  opportunity_flipper_offers__c: string;
  sfid: string;
  offeramount: number;
  offerdate: string;
}

const isEdgeOffer = (offer: Offer) => !offer?.tier?.toLowerCase().includes('essential');

type HomeownerOffersProps = {
  property: any;
};

export const HomeownerOffers: FC<HomeownerOffersProps> = ({ property }) => {
  const maxOffer = formatNumToCurrency(
    property.offers.reduce((amount: number, offer: Offer) => Math.max(amount, offer.offeramount), 0)
  );

  return (
    <div className={styles.propertyWrapper}>
      <div className={styles.propertyContainer}>
        <h1>Your Offers</h1>
        <h3>
          You’ve received <u>{property.offers.length}</u> offers and your highest offer is
        </h3>
        <h1>{maxOffer}</h1>
        <div className={styles.tableContainer}>
          <div
            className={classNames('row', styles.offerDiv, 'headers')}
            style={{ borderBottom: '2px solid #999999' }}
          >
            <div className="col-4 pt-2 pb-2 text-right">
              <span className={styles.header}>AMOUNT</span>
            </div>
            <div className="col-3 pt-2 pb-2 text-center">
              <span className={styles.header}>OFFER DATE</span>
            </div>
            <div className="col-5 pt-2 pb-2 text-left">
              <span className={styles.header}>OFFER ID</span>
            </div>
          </div>

          <div className={classNames('row', styles.offersData)}>
            <div className="col">
              {property.offers.map((offer: Offer, idx: number) => (
                <div className={classNames('row', styles.offerDiv)} key={idx}>
                  <div className={classNames('col-4', 'pt-2', 'pb-2', 'text-right')}>
                    <span>{formatNumToCurrency(offer.offeramount)}</span>
                    {isEdgeOffer(offer) && (
                      <img src={edgeIcon.src} alt="" height="10" className="pl-1" />
                    )}
                  </div>
                  <div className={classNames('col-3', 'pt-2', 'pb-2', 'text-center')}>
                    {offer.offerdate}
                  </div>
                  <div className={classNames('col-5', 'pt-2', 'pb-2', 'text-left')}>
                    {(offer.sfid || '').toUpperCase()}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={styles.info}>
          *Offers with an Edge badge are from investors who are part of Sundae’s Edge membership
          program
        </div>
      </div>
    </div>
  );
};
