import React, { FC } from 'react';

import logo from '../../../../../public/logo.svg';

import styles from './HomeownerHeader.module.scss';

export const HomeownerHeader: FC = () => (
  <nav className={styles.header}>
    <a className={styles.headerLogo} href="#">
      <img src={logo.src} alt="Sundae" height="25" />
    </a>
    <a className={styles.headerPhone} href="tel:1-800-214-4426">
      <img src="https://sundae.com/wp-content/themes/sundae/assets/img/phone-icon.svg" alt="" />
      1-800-214-4426
    </a>
  </nav>
);
