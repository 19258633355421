import React, { FC } from 'react';

import landingHome from '../../../../../public/images/homeowner/landing-home.png';
import logo from '../../../../../public/logo.svg';

import styles from './HomeownerFooter.module.scss';

export const HomeownerFooter: FC = () => (
  <div className={styles.footer}>
    <img className={styles.footerHome} src={landingHome.src} alt="" />
    <ul className={styles.footerLinks}>
      <li>
        <a className="dark" href="https://sundae.com/blog/investor/">
          Blog
        </a>
      </li>
      <li>
        <a className="dark" href="https://sundae.com/about-us">
          About Us
        </a>
      </li>
      <li>
        <a className="dark" href="mailto:investors@sundae.com">
          Contact us
        </a>
      </li>
    </ul>
    <p className={styles.footerInfo}>
      Sundae is a licensed real estate broker in California (DRE # 02088298), Colorado, Florida,
      Georgia, Nevada (#B.1002940.CORP), Utah, Tennessee, and Washington.
    </p>
    <img className={styles.footerLogo} alt="Sundae" src={logo.src} />
  </div>
);
