import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

import { getPropertyDetails } from '../../api/homeowner/property';
import { getCompactAddress } from '../../utils/property.utils';

import styles from './HomeownerPropertyView.module.scss';
import { HomeownerFooter } from './components/HomeownerFooter/HomeownerFooter';
import { HomeownerHeader } from './components/HomeownerHeader/HomeownerHeader';
import { HomeownerImages } from './components/HomeownerImages/HomeownerImages';
import { HomeownerMap } from './components/HomeownerMap/HomeownerMap';
import { HomeownerOffers } from './components/HomeownerOffers/HomeownerOffers';
import { HomeownerWidgets } from './components/HomeownerWidgets/HomeownerWidgets';

const HomeownerPropertyView = ({ sfid }: { sfid: string }) => {
  const [property, setProperty] = useState<any>();

  useEffect(() => {
    if (!sfid) return;
    getPropertyDetails(sfid).then((resp) => {
      setProperty(resp.data);
    });
  }, [sfid]);

  const ownerName = property?.propertyownername || '';

  // TODO: rename in backend streetaddress -> streetAddress; zipcode -> zipCode (INV-3098)
  const address = getCompactAddress({
    ...(property || {}),
    streetAddress: property?.streetaddress || property?.streetAddress,
    zipCode: property?.zipcode || property?.zipCode
  });

  const investorsCount =
    (property?.city || '').toUpperCase() === 'INLAND EMPIRE'
      ? 614
      : (property?.city || '').toUpperCase() === 'SAN DIEGO'
      ? 485
      : 'many';

  return (
    <div className={styles.root}>
      <HomeownerHeader />
      {property && (
        <>
          <div className={styles.textContainer}>
            <h1>
              {`Hi${ownerName ? ` ${ownerName}` : ''}, thanks for choosing the Sundae Marketplace.`}
            </h1>
            <h3 className="mt-3 mb-1">
              Here’s your property profile and offer details for
              <br />
              {address}
            </h3>
          </div>
          {property.geocodelatitude && property.geocodelongitude && (
            <HomeownerMap
              coords={{ lat: property.geocodelatitude, lng: property.geocodelongitude }}
            />
          )}

          <HomeownerOffers property={property} />

          <div className={classNames(styles.textContainer, styles.propertyProfileContainer)}>
            <h1>
              Your Property Profile for <br />
              {address}
            </h1>
            <h3>
              Your property profile has been shared with {investorsCount} investors <br />
              who buy in {property.city}.
            </h3>
          </div>

          <HomeownerImages images={property.images} />

          <HomeownerWidgets property={property} />

          <HomeownerFooter />
        </>
      )}
    </div>
  );
};

export default HomeownerPropertyView;
