import GoogleMapReact, { Coords } from 'google-map-react';
import React, { FC } from 'react';

import { MapMarker } from '../../../../components/MapMarker/MapMarker';

import styles from './HomeownerMap.module.scss';

type HomeownerMapProps = {
  coords: Coords;
};

export const HomeownerMap: FC<HomeownerMapProps> = ({ coords }) => (
  <div className={styles.mapContainer}>
    <GoogleMapReact
      bootstrapURLKeys={{ key: 'AIzaSyBTmJP5EEgdE8Xn592c3ZYKFxv46Q5sMMM' }}
      defaultZoom={16}
      defaultCenter={coords}
      center={coords}
    >
      <MapMarker {...coords} />
    </GoogleMapReact>
  </div>
);
