import { useQuery } from '@tanstack/react-query';

import { api } from '../_base';

// GET /homeowner/property/{sfId}
export const getPropertyDetails = (sfId: string) => {
  return api.homeownerControllerPropertyDetails(sfId);
};

export const usePropertyDetails = (sfId: string) => {
  return useQuery({
    queryKey: ['getPropertyDetailsHomeowner'],
    queryFn: () => getPropertyDetails(sfId)
  });
};
