import classNames from 'classnames';
import React, { FC } from 'react';

import iconHome from '../../../../../public/images/homeowner/icon-home.svg';

import styles from './HomeownerWidgets.module.scss';

type HomeownerWidgetsProps = {
  property: any;
};

export const HomeownerWidgets: FC<HomeownerWidgetsProps> = ({ property }) => (
  <div className={styles.widgetsContainer}>
    {property.homeinspection && (
      <div className={styles.widget}>
        <h3>Home Inspection Report</h3>
        <a
          target="blank"
          href={property.homeinspection}
          className={classNames(styles.widgetContent, styles.homeInspectionWidget)}
        >
          <h1>Your Inspection Report</h1>
          <img src={iconHome.src} alt="home" />
        </a>
      </div>
    )}
    {property.matterporturl && (
      <div className={styles.widget}>
        <h3>Matterport</h3>
        <iframe
          className={styles.widgetContent}
          id="matterportIframe"
          src={property.matterporturl}
          allowFullScreen
          allow="xr-spatial-tracking"
        ></iframe>
      </div>
    )}
    {property.floorplan && (
      <div className={styles.widget}>
        <h3>Floorplan</h3>
        {property.floorplan &&
          (property.floorplan.match(/\.(jpeg|jpg|gif|png)$/) ? (
            <a target="blank" href={property.floorplan}>
              <img
                alt=""
                className={styles.widgetContent}
                style={{ objectFit: 'contain' }}
                src={property.floorplan}
              />
            </a>
          ) : (
            <iframe
              className={styles.widgetContent}
              id="matterportIframe"
              src={property.floorplan}
              allowFullScreen
              allow="xr-spatial-tracking"
            ></iframe>
          ))}
      </div>
    )}
  </div>
);
