import compact from 'lodash/compact';

import {
  ListingListWithOfferEntryApiModel,
  PropertyDetailsResponseBody,
  PropertyStatus
} from '../api/_base/generated/data-contracts';

export const isAutoOfferVisible = (
  property: PropertyDetailsResponseBody | ListingListWithOfferEntryApiModel
) => {
  const { autoOfferIsEnabled, propertyStatus } = property;

  return autoOfferIsEnabled && propertyStatus === PropertyStatus.ACTIVE;
};

export const getCompactAddress = (property: Partial<PropertyDetailsResponseBody>) =>
  compact([
    property.streetAddress,
    property.city,
    compact([property.state, property.zipCode]).join(' ')
  ]).join(', ');
