import { Splide, SplideSlide } from '@splidejs/react-splide';
import React, { FC } from 'react';

type HomeownerImagesProps = {
  images?: Array<{
    imageurl: string;
  }>;
};

export const HomeownerImages: FC<HomeownerImagesProps> = ({ images = [] }) => (
  <Splide options={{ height: '300px', autoWidth: true, gap: '10px' }}>
    {images.map((image, idx) => (
      <SplideSlide key={idx}>
        <img src={image.imageurl} style={{ height: '300px' }} alt="" />
      </SplideSlide>
    ))}
  </Splide>
);
