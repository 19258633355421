import Head from 'next/head';
import { useRouter } from 'next/router';
import React from 'react';

import HomeownerPropertyView from '../../src/containers/HomeownerPropertyView/HomeownerPropertyView';

const HomeownerPage = () => {
  const router = useRouter();
  const { sfid } = router.query as { sfid: string };

  return (
    <>
      <Head>
        <title>Sundae - Marketplace</title>
      </Head>
      <HomeownerPropertyView sfid={sfid} />
    </>
  );
};

export default HomeownerPage;
