import { Coords } from 'google-map-react';
import React, { FC } from 'react';

import { PropertyPinIcon } from '../Icons';

import styles from './MapMarker.module.scss';

type MarkerProps = Coords & {
  children: React.ReactNode;
  className?: string;
};

const Marker: FC<MarkerProps> = ({ children, className }) => (
  <div className={className}>{children}</div>
);

type MapMarkerProps = Coords;

export const MapMarker: FC<MapMarkerProps> = ({ lat, lng }) => {
  return (
    <Marker lat={lat} lng={lng}>
      <PropertyPinIcon className={styles.propertyPinIcon} />
    </Marker>
  );
};
